
<template>
    <div class="bottom">
        <div class="copyrightBox">
            <div style="width: 100%; background: #262626;display: flex;justify-content: center;">
                <div class="contactBox">
                    <div class="QRCodeBox">
                        <div>
                            <img src="../assets/zhongcai.png" alt="">
                        </div>
                        <div>
                            <img src="../assets/haoguanjia.png" alt="">
                        </div>
                    </div>
                    <div class="divider">

                    </div>
                    <div class="contactUs">
                        <div class="contactsText">
                            <div class="textYiyi">关于好管家</div>
                            <div class="detailsText">
                                <div @click="jumpClick(1)">好管家简介</div>
                            </div>
                        </div>
                        <div class="contactsText">
                            <div  class="textYiyi">热点资讯</div>
                            <div class="detailsText">
                                <div @click="jumpClick(2)">新闻资讯</div>
                            </div>
                        </div>
                        <div class="contactsText">
                            <div  class="textYiyi">商家入驻</div>
                            <div class="detailsText">
                                <div @click="jumpClick(3)">加入我们</div>
                            </div>
                        </div>
                        <div class="contactsText">
                            <div  class="textYiyi">联系我们</div>
                            <div class="detailsText">
                                <div @click="jumpClick(4)">客服热线</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footBottomITextBox">
                <div class="footBottomIText">
                    <div>
                        浙公网安备 33080302000114号 | <span style="cursor: pointer;" @click="goPage()">浙ICP备16039221号</span>  | 违法和不良信息举报电话：05708768891Copyright © 2024-present All Rights Reserved
                    </div>
                    <div class="copyright">
                        浙江好管家ZHONGCAIMALL.COM 版权所有
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>
export default {
    data () {
        return {
            searchInput: "",
            fullPath:''
        }
    }, 
	created() {
        this.fullPath = this.$router.history.current.fullPath;
	},
    methods: {    
        jumpClick(type){
            if(type == 1 && this.fullPath != "/introducePage"){
                this.$router.push({path:'/introducePage'});
            }else if(type == 2 && this.fullPath != "/newsAndInformation"){
                this.$router.push({path:'/newsAndInformation'});
            }else if(type == 3){
                window.open('https://hgjmallpc.zchjds.com/SSMThree/pszx/tologin.html', '_blank');
            }else if(type == 4 && this.fullPath != "/contactUs"){
                this.$router.push({path:'/contactUs'});
            }else{
                location.reload();
            }
            window.scrollTo({
                top: 0
            });
        },
        goPage(){
            window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
        }
    }
};
</script>

<style lang="scss" scoped>
.copyrightBox{
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.contactBox{
    display: flex;
    height: 450px;
    width: 1400px;   
    align-items: center;
    justify-content: space-evenly; 
}
.QRCodeBox{
    div{
        margin: 0 20px;
        img{
            width: 240px;
            height: 56px;
            margin-top: 43px;
            margin-bottom: 43px;
        }
        div{
            color: #FFFFFF;
        }
    }
}
.divider{
    background: #FFFFFF;
    width: 2px;
    height: 274px;
}
.contactUs{
    height: 274px;
    color: #FFFFFF;
    display: flex;
    justify-content: space-evenly;
    width: 1100px;
    .contactsText{
        .textYiyi{
            font-size: 26px;
            font-weight: normal;
        }
        .detailsText{
            margin-top: 38px;
            font-size: 18px;
            div{
                padding: 5px 0;
                cursor: pointer;
            }
        }
    }
}
.footBottomITextBox{
    width: 100%; background: #191919;
}
.footBottomIText{
    padding: 47px 0 92px;
    font-size: 13px;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    .copyright{
        padding-top: 14px;
    }
}

@media (max-width: 1400px) {
    .copyrightBox{
        width: 1400px;
    }
}

</style>