import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
// axios.defaults.baseURL = 'http://192.168.1.107:80'
import router from './router/router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import HeaderTop from './components/headerTop';
import FootBootom from './components/footBootom'
Vue.component("header-top", HeaderTop);
Vue.component("foot-bootom", FootBootom);

Vue.prototype.$axios = axios

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
    router,
    beforeCreate() {
        Vue.prototype.$bus = this
    },
    render: h => h(App),
}).$mount('#app')