
<template>
    <div class="top">
        <div class="logo_search">
            <div class="kong">
                <div class="logo" @click="goTohomepage">
                    <img src="../assets/pc_logo.png" alt="">
                </div>
                <div class="search">
                    <div class="search_inputDiv">
                        <input class="search_input" type="text" v-model="searchInput" @keyup.enter="keyUpOperate()" placeholder="请输入关键字">
                        <div class="search_input_text" @click="oninput()">搜索</div>
                    </div>
                    <div class="search_but">
                        <div :class="selected_but == 1 ? 'selected':''" @click="selecteds(1)">首页</div>
                        <div  :class="selected_but == 2 ? 'selected':''"  @click="selecteds(2)">关于好管家</div>
                        <div  :class="selected_but == 3 ? 'selected':''"  @click="selecteds(3)">热点资讯</div>
                        <div  :class="selected_but == 4 ? 'selected':''"  @click="selecteds(4)">商家入驻</div>
                        <div  :class="selected_but == 5 ? 'selected':''"  @click="selecteds(5)">联系我们</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>
export default {
    data () {
        return {
            searchInput: "",
            loadingInstance: "",
            selected_but:"1",
            fullPath:""
        }
    },
    created() {
        this.searchInput = this.$route.query.prodName || ''
        this.fullPath = this.$router.history.current.fullPath;
        if(this.$route.path == "/newsAndInformation" || this.$route.path == "/newsAndInDetails"){
            this.selected_but = 3
        }else if(this.$route.path == "/contactUs"){
            this.selected_but = 5
        }else if(this.$route.path == "/introducePage"){
            this.selected_but = 2
        }else{
            this.selected_but = 1
        }
	},
    methods: {
        goTohomepage(){
            if(this.fullPath=="/"){
                location.reload();
            }else{
                this.$router.push({path:'/'});
                // location.reload();
            }
        },
        oninput(){
            this.inputBoxChange();
        },
        keyUpOperate(){
            this.inputBoxChange();
        },
        selecteds(type1){
            /* eslint-disable */
            if(type1 == "1" && this.fullPath != "/"){
                this.goTohomepage();
                this.selected_but = type1
            }else if(type1 == "2" && this.fullPath != "/introducePage"){
                this.$router.push({path:'/introducePage'});
                this.selected_but = type1
            }else if(type1 == "3" && this.fullPath != "/newsAndInformation"){
                this.$router.push({path:'/newsAndInformation'});
                this.selected_but = type1
            }else if(type1 == "4"){
                window.open('https://hgjmallpc.zchjds.com/SSMThree/pszx/tologin.html', '_blank');
                this.selected_but = 1
            }else if(type1 == "5" && this.fullPath != "/contactUs"){
                this.$router.push({path:'/contactUs'});
                this.selected_but = type1
            }else{
                location.reload();
            }
            window.scrollTo({
                top: 0
            });
        },
        inputBoxChange(){
            console.log(this.$route.name);
            if(this.$route.name == "searchForPage"){
                this.$bus.$emit('customEvent',this.searchInput);
            }
                this.$router.push({path:'/searchForPage',query:{prodName:this.searchInput}});
            // if(this.$route.query.prodName == this.searchInput){
            //     location.reload();
            // }else if(this.$route.query.prodName == undefined){
            //     this.$router.push({path:'/searchForPage',query:{prodName:this.searchInput}});
            //     // location.reload();
            // } else{
                // this.$router.push({path:'/searchForPage',query:{prodName:this.searchInput}});
                // location.reload();
            // }            
        },
    }
};
</script>

<style lang="scss" scoped>
.top{
    width: 100%;
}
.blackdiv{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 35px;
    background: #333333;
}
.buttonDiv{
    width: 1280px;
    text-align: end;
    padding-right: 10px;
}
.buttonDiv > div{
    display: inline-block;
    color: #B0B0B0;
    height: 35px;
    line-height: 35px;
    font-size: 11px;
    cursor: pointer;
}
.logo_search{
    height: 173px;
    width: 100%;
    background: #FFF;
    display: flex;
    justify-content: space-around;   
}
.kong{
        width: 1280px;
        display: flex;
        justify-content: space-between;
        .logo{
            width: 300px;
            height: 78px;
            padding-top: 50px;
            padding-bottom: 45px;
            cursor: pointer;   
            img{
                width: 100%;
                height: 100%;
            }
        }
        .search{
            width: 667px;
            height: 52px;
            padding-top: 50px;
            padding-bottom: 45px;
            float: right;
            .search_inputDiv{
                position: relative;
                .search_input{
                    width: 667px;
                    height: 52px;
                    border-radius: 8px;
                    box-sizing: border-box;
                    border: 2px solid #FF9D1B;
                    font-size:19px;
                    padding-left:10px;
                    outline: none;
                }
                input::-webkit-input-placeholder{
                    position:relative;
                     left: .5rem;
                } 
                .search_input_text{
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 52px;
                    width: 140px;
                    text-align: center;
                    line-height: 52px;
                    background: #FF9D1B;
                    font-size: 21px;
                    font-weight: 500;
                    color: #FFFFFF;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    cursor: pointer;
                }
            }
            .search_but{
                padding-top: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                div{
                    cursor: pointer;
                }
                .selected{
                    color: #FFA942;
                }
            }         
        }
    }  

@media (max-width: 1280px) {
    .blackdiv{
        display: block;
        width: 1280px;
    }
    .logo_search{
        display: block;
        .kong{
            display: block;
            .logo{
                float: left;
            }
            .search{
                float: right;
            }
        }

    }
}

</style>