import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
var router = new Router({
    routes: [{
            path: "/",
            name: 'homePage',
            component: () =>
                import ('@/view/HomePage.vue')
        }, {
            path: "/detailsPage",
            name: 'detailsPage',
            component: () =>
                import ('@/view/detailsPage.vue')
        }, {
            path: "/searchForPage",
            name: 'searchForPage',
            component: () =>
                import ('@/view/searchForPage.vue')
        }, {
            path: "/newsAndInformation",
            name: 'newsAndInformation',
            component: () =>
                import ('@/view/newsAndInformation.vue')
        }, {
            path: "/newsAndInDetails",
            name: 'newsAndInDetails',
            component: () =>
                import ('@/view/newsAndInDetails.vue')
        }, {
            path: "/contactUs",
            name: 'contactUs',
            component: () =>
                import ('@/view/contactUs.vue')
        }, {
            path: "/introducePage",
            name: 'introducePage',
            component: () =>
                import ('@/view/introducePage.vue')
        }
        //新添加的页面都写在这块...
    ]
});
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
        return originalPush.call(this, location).catch(err => err)
    }
    // const originalPush = Router.prototype.push;

// // 对push方法进行重写
// Router.prototype.push = function(location, resolve, reject) {
//         if (resolve && reject) {
//             originalPush.call(this, location, resolve, reject);
//         } else {
//             originalPush.call(this, location, () => {}, () => {});
//         }
//     }
//     //重写replace方法
// const originReplace = Router.prototype.replace
// Router.prototype.replace = function(location, resolve, reject) {
//     if (resolve && reject) {
//         originReplace.call(this, location, resolve, reject);
//     } else {
//         originReplace.call(this, location, () => {}, () => {});
//     }
// }

export default router;